import { React, useState, useEffect } from 'react';
import nayidishalogofooter from '../img/nayidishalogofooter.png';
import nayidishafooter from '../img/nayidishafooter.svg'

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import otp from './otp.svg';
import sideimg from './side-img.png';
import { Link } from "react-router-dom";
import nayeedishafooterfin from '../img/nayeedishafooter-fin.svg';
import { IoIosCloseCircle } from "react-icons/io";
import HorizontalLinearStepper from '../pages/registration-steper/horizontalLinearStepper'
import { Button } from 'react-bootstrap';
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import apiUrl from '../setting/apiConfig'
import { FaCookieBite } from "react-icons/fa";
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { SiReacthookform } from "react-icons/si";
import { PiDownload } from "react-icons/pi";
import whatsapp from '../img/whatsapp.svg'
console.log(getCookieConsentValue(Cookies));

const Footer = () => {
  const handleAccept = () => {
    console.log('User accepted cookies.');
  };

  const handleDecline = () => {
    console.log('User declined cookies.');
  };



  const [universities, setUniversities] = useState([]);
  const [newsdetails, setNewsdetails] = useState([]);
  const [testimonial, setTestimonial] = useState([]);
  const [exams, setExams] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const universityResponse = await fetch(apiUrl + 'university-details');
        const universityData = await universityResponse.json();

        // Filter universities for Russia
        const russiaUniversities = universityData.filter(university => university.country === 'Russia');

        setUniversities(russiaUniversities);
        console.log('Footer University Data:', russiaUniversities);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);





  return (
    <>

      <div className='footer1 p-5'>


        <div className='container'>


          <div className='row'>
            <h5>MBBS Russia</h5>
            {universities.map(university => (
              <div key={university.uid} className='col-lg-3 col-12'>

                <a href={`university/${university.slug}`}>

                  {university.name}
                </a>
              </div>
            ))}
          </div>



          <div className='row pt-5'>


            <div className='col-lg-4 col-6'>
              <h5>MBBS Philippines</h5>


              <a href='/mbbs-in-brokenshire-college-of-medicine-philippines'> MBBS in Brokenshire College of Medicine Philippines</a>
              {/* <a href=''> Marketing</a> */}



            </div>



            <div className='col-lg-4 col-6 mt-4'>


              <a href='/mbbs-in-our-lady-of-fatima-university-philippines'> MBBS in Our Lady of Fatima University Philippines</a>




            </div>



            <div className='col-lg-4 col-6 mt-4'>


              <a href='/mbbs-in-our-lady-of-fatima-university-philippines'>MBBS in University of Perpetual Help System Dalta Philippines</a>



            </div>






            {/* <div className='col-md-2 col-6 mt-4'>


              <a href=''> Healthcare Management</a> <br></br>
              <a href=''> View More</a>



            </div> */}


          </div>





          <div className='row pt-5'>

            <div className='col-lg-4  col-6'>


              <h5>MBBS Kyrgyzystan</h5>



              <a href='/mbbs-in-osh-state-medical-university-kyrgyzstan'> MBBS in OSH State Medical University Kyrgyzstan</a>
              {/* <a href=''> Marketing</a> */}



            </div>



            <div className='col-lg-4 col-6 mt-4'>


              <a href='/mbbs-in-jalalabad-state-university-kyrgyzstan'> MBBS in Jalalabad State University Kyrgyzstan</a>




            </div>



            <div className='col-lg-4 col-6 mt-4'>


              <a href='/mbbs-in-kazakh-national-medical-university-kyrgyzstan'>MBBS in Kazakh National Medical University Kyrgyzstan</a>



            </div>









          </div>




        </div>

      </div>

      <div className='footer2 p-5'>


      </div>

      <div className="footer3 p-5">

        <div className='container'>

          <div className='row'>

            <div className='col '>

              <a className="navbar-brand foot-res1" href="/"><img src={nayeedishafooterfin} alt="" style={{ width: "200px" }} />

              </a>

              <div className='pt-4 foot-res2'>
                <p><b>Gorakhpur office -</b>
                  Nayee Disha Education
                  Ground Floor, B-361, Sidharth Enclave, taramanadal,
                  Near GDA office, Gorakhpur</p>

                <a href="tel:+91 8860031900"><p>+91 8860031900</p></a>

                <p>info@nayeedisha.in</p>


              </div>

            </div>


            <div className='col pt-2 '>

              <h5 className='font-weight-600 office_address'>Office Address</h5>
              <div className='pt-4'>
                <p><b>Greater Noida -</b> Suite 1405, 1407, 14th Floor,T3, Techzone-4, NX One, Greater Noida West, Uttar Pradesh 201306</p>
                <a href="tel:+91 8860031900"><p>+91 8860031900</p></a><p>info@nayeedisha.in</p>
              </div>

            </div>



            <div className='col pt-2'>

              <h5>Tools & Research</h5>
              <div className='pt-4'>
                <p>Career Compass</p>

                <p>Write a review</p>

                <p>QnA Forum</p>

                <p>DU Predictor</p>

                <p>Unit Calculator</p>

              </div>

            </div>


            <div className='col pt-2'>

              <h5>Quick Links</h5>
              <div className='pt-4'>
                <Link to={"/about-us"} ><p>About us</p></Link>

                <Link to={"/contact-us"} ><p>Contact us</p></Link>

                <Link to={"/blogs"} ><p>Latest Blogs</p></Link>
                <Link to={"/exams"} ><p>Exams Update</p></Link>
              </div>

            </div>


            <div className='col pt-2'>

              <h5>Follow</h5>
              <div className='pt-4'>
                <a href='https://www.facebook.com/people/NayeeDisha-Study-Abroad/61559962673504/' target="_blank"><p>Facebook</p></a>

                <a href='https://x.com/NayeeInfo' target="_blank"><p>Twitter</p></a>

                <a href='https://www.instagram.com/disha.nayee/?hl=en' target="_blank"> <p>Instagram</p></a>

                <a href='https://www.linkedin.com/in/nayee-disha-ab3a55308' target="_blank"><p>Linkedin</p></a>


              </div>
            </div>



          </div>

        </div>


      </div>










      {/* modal */}

      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">

        <div className=" modal-dialog modal-xl">
          <Button variant="secondary closebutton"><IoIosCloseCircle className='close_icon_width' /></Button>
          <div className="modal-content">
            <HorizontalLinearStepper />
          </div>

        </div>
      </div>



      <div className='text-white py-3 footer3'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <p>Copyright © 2024 All Rights Reserved.</p>
            </div>
            <div className='col-md-6'>
              <div className='footer_Privacy d-flex justify-content-center'>
                <a href='/privacy-policy'> <p>Privacy Policy</p> </a>
                &nbsp;  |  &nbsp;
                <a href='terms-and-conditions'><p>Terms and conditions</p></a>
                &nbsp;  |  &nbsp;
                <a href='tel:+91 8860031900'><p>Contact our Admissions Counselor  : +91 8860031900</p></a>
              </div>

            </div>
          </div>
        </div>


      </div>



      {/* end modal */}








      {/* SECOND MODAL */}
      {/* modal start */}



      {/* 


      {/* modal end */}
<CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      cookieName="NayeedishaEdu"
      onAccept={handleAccept}
      onDecline={handleDecline}
      style={{
        background: "#333",
        color: "#fff",
        padding: "20px",
        fontSize: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        zIndex: "1000",
        borderTop: "3px solid #FFD700",
      }}
      buttonStyle={{
        background: "#FFD700",
        color: "#000",
        fontSize: "14px",
        padding: "10px 20px",
        borderRadius: "5px",
        cursor: "pointer",
      }}
      declineButtonStyle={{
        background: "#ff4d4d",
        color: "#fff",
        fontSize: "14px",
        padding: "10px 20px",
        borderRadius: "5px",
        cursor: "pointer",
      }}
      expires={365}
      enableDeclineButton
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <FaCookieBite size={24} style={{ marginRight: "10px", color: "#FFD700" }} />
        <span>This website uses cookies to enhance the user experience.</span>
      </div>
    </CookieConsent>



      {/*  */}

      <a href="https://wa.me/+918860031900" className='float' target="_blank">
        <img src={whatsapp} className="whatspp" alt='Nayeedisha' />
      </a>
    </>
  );
};

export default Footer;
